import { Link } from "gatsby";
import React from "react";

/**
 * Content for all State Business Bank Account Requirements Blogs.
 *
 * @param {string} props.introText - Text displayed as an H4 at the top of the page
 * @param {StaticImage} props.image - Image for the blog. Should be a Static Image component.
 * @param {React.JSX.Element} props.considerContent - Content for the Consider WaFd Bank section.
 * @returns Blog Content
 */
const StateBusinessBankAccountRequirementsBlogContent = (props) => (
  <>
    <h4>{props.introText}</h4>
    {props.image}
    <h2>How Do Requirements Vary by Business Type</h2>
    <p>
      Your{" "}
      <a
        id="blog-irs-business-structures-1-external-link"
        href="https://www.irs.gov/businesses/small-businesses-self-employed/business-structures"
        target="_blank"
        rel="noopener noreferrer"
      >
        business structure
      </a>{" "}
      (sole proprietorship, partnership, limited liability company, etc.) makes a big difference when it comes to the
      different requirements your business is subject to and what documents are needed for different tasks, including
      opening a business account.
    </p>
    <p>
      Sole proprietorships use either the owner's Social Security number (SSN) or an{" "}
      <a
        id="blog-irs-employer-id-numbers-external-link"
        href="https://www.irs.gov/businesses/small-businesses-self-employed/employer-id-numbers"
        target="_blank"
        rel="noopener noreferrer"
      >
        employer identification number
      </a>{" "}
      (EIN) and have less distinction between personal and business funds and assets than other business types. You also
      will have an operating agreement or corporate bylaws if your business is set up as an LLC or corporation. There
      are many tax and legal implications to consider when choosing a business structure, so carefully consider how your
      business' operations (and yourself as the owner) might be impacted by state and federal laws. Find more
      information on business structure and other things to consider from{" "}
      <a
        id="blog-irs-business-structures-2-external-link"
        href="https://www.irs.gov/businesses/small-businesses-self-employed/business-structures"
        target="_blank"
        rel="noopener noreferrer"
      >
        the IRS
      </a>
      .
    </p>
    <p>
      Reach out to your bank to learn about specific requirements for opening a business bank account once you've
      decided the type and size of your business. Requirements can vary based on your business structure, what type of
      account you want to open, and{" "}
      <Link
        to="/blog/small-business/what-is-the-best-bank-for-a-small-business"
        id="blog-what-is-the-best-bank-for-a-small-business-link"
      >
        what bank you choose
      </Link>
      .
    </p>
    <h2>What Do You Need to Open a Business Bank Account</h2>
    <p>
      Opening a business checking account allows you to deposit funds, process payroll, pay for expenses by check, and
      much more. Typically, opening a{" "}
      <Link id="blog-business-checking-account-1-link" to="/business-banking/business-checking-account">
        business checking account
      </Link>{" "}
      requires:
    </p>
    <ul>
      <li>Your EIN or SSN</li>
      <li>The legal name of your business</li>
      <li>Your business' DBA (doing business as) name, if it's different from the legal name</li>
      <li>Two sets of documentation:</li>
      <ul>
        <li>Legal formation verification including:</li>
        <ul>
          <li>A copy of your business license or business license number</li>
          <li> Print out from the Secretary of State verifying active status of your business</li>
        </ul>
        <li>Formation documents such as:</li>
        <ul>
          <li>Articles of Incorporation</li>
          <li>Operating Agreement</li>
          <li>Articles of Organization</li>
          <li>Meeting Minutes</li>
        </ul>
      </ul>
    </ul>
    <h2>What Documents Are Required to Open a Business Savings Account</h2>
    <p>
      A{" "}
      <Link id="blog-business-savings-account-1-link" href="/business-banking/business-savings-account">
        business savings account
      </Link>{" "}
      helps separate your daily operating expenses from funds you'd like to use in the future. Depending on the bank you
      choose, you may also be able to earn interest, which can be invested back into your business. The requirements for
      opening a business savings account are the same as the requirements for a business checking account (listed
      above). Check with your bank to learn more.
    </p>
    <h2>Consider WaFd Bank for Your Business Banking</h2>
    {props.considerContent}
    <ul>
      <li>
        <Link id="blog-business-checking-account-2-link" to="/business-banking/business-checking-account">
          Business checking accounts
        </Link>{" "}
        designed with your business in mind
      </li>
      <li>
        <Link id="blog-business-savings-account-2-link" to="/business-banking/business-savings-account">
          Business savings accounts
        </Link>{" "}
        with benefits like the ability to earn interest
      </li>
      <li>
        <Link
          id="blog-business-line-of-credi-term-loans-link"
          to="/business-banking/business-line-of-credit#business-term-loans-section"
        >
          Business term loans
        </Link>{" "}
        to help you purchase equipment, make capital improvements, and/or finance future expansions
      </li>
      <li>
        <Link id="blog-business-line-of-credit-link" to="/business-banking/business-line-of-credit">
          Business lines of credit
        </Link>{" "}
        so you can borrow funds or support seasonal or cyclical cash flow
      </li>
      <li>
        <Link id="blog-business-services-1-link" to="/business-banking/business-services">
          Business services
        </Link>{" "}
        to help your business quickly and securely process payments from clients or customers
      </li>
      <li>
        <Link id="blog-payroll-processing-link" to="/business-banking/business-services/hr-and-payroll-services">
          Payroll processing services
        </Link>{" "}
        to pay yourself and your employees
      </li>
      <li>
        <Link id="blog-collect-early-link" to="/business-banking/business-services/collect-early">
          CollectEarly™
        </Link>{" "}
        so you can get paid from customers and vendors in hours instead of months
      </li>
      <li>
        <Link id="blog-acquisition-financing-link" to="/commercial-banking/acquisition-financing">
          Acquisition financing
        </Link>{" "}
        if you're looking to buy or sell a business
      </li>
      <li>
        <Link id="blog-business-equipment-financing-link" to="/commercial-banking/business-equipment-financing">
          Equipment financing
        </Link>{" "}
        to replace or add equipment for business use
      </li>
      <li>
        <Link id="blog-treasury-management-services-link" to="/commercial-banking/treasury-management-services">
          Treasury Management
        </Link>{" "}
        to efficiently manage your cash flow
      </li>
    </ul>

    <p>
      At WaFd Bank, we want to make things as simple as possible so you can focus on building a successful business.
      Find your{" "}
      <Link to="/locations" id="blog-internal-locations-page-link">
        local WaFd Bank branch
      </Link>{" "}
      to learn more or open a{" "}
      <Link to="/business-banking/business-checking-account" id="blog-internal-business-checking-page-link">
        business bank account online
      </Link>{" "}
      today!
    </p>
  </>
);

export default StateBusinessBankAccountRequirementsBlogContent;

StateBusinessBankAccountRequirementsBlogContent.defaultProps = {
  introText: "",
  image: null, // This should be a StaticImage
  considerContent: null // This should be a <p>
};

